<template>
  <router-view></router-view>
</template>

<script>
import LojasApps from './components/LojasApps.vue'
import Footer from './components/Footer.vue'
import 'bootstrap/dist/css/bootstrap.min.css'

export default {
  name: 'App',
  components: {
    LojasApps,
    Footer
  }
}
</script>

<style scoped>
 
 #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;

}


</style>
